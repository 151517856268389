import React from 'react';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import { graphql, PageProps } from 'gatsby';

import LayoutWrapper, { intlWrapperHOC, v4tov3HOC } from '../../components/LayoutWrapper';
import Breadcrumb from '../../components/Breadcrumb';
import HeroPost from '../../components/HeroPost';
import { RichTextField } from '../../components/RichTextField/RichTextField';
import { SinglePromotionPageTermsAndConditionsQuery } from '../../../graphql.schema';
import { GlobalPageContext } from '../../types';
import { v4tov3base } from '../../lib/mapV4toV3';

import * as styles from './promotions-page.module.scss';

type PageContextType = GlobalPageContext & {
  id: string;
};

const TermsAndConditionsPage: React.FC<PageProps<SinglePromotionPageTermsAndConditionsQuery, PageContextType>> = ({
  data,
  pageContext,
}) => {
  const { promotion } = data.strapi;
  const { websiteLocale: websiteLocaleV3 } = pageContext;
  const websiteLocale = v4tov3base({ id: websiteLocaleV3.id, ...websiteLocaleV3.attributes });
  const intl = useIntl();

  if (!websiteLocale || !promotion) return <p>Not found</p>;

  return (
    <LayoutWrapper {...pageContext}>
      <div className="container">
        <Breadcrumb
          hasRightToLeft={true}
          pageContext={pageContext}
          lastCrumb={intl.formatMessage({ id: 'termsAndConditionsTitle', defaultMessage: 'Terms and Conditions' })}
        ></Breadcrumb>
      </div>
      <HeroPost
        variant="default"
        title={intl.formatMessage({ id: 'termsAndConditionsTitle', defaultMessage: 'Terms and Conditions' })}
      />
      <div className="date is-flex is-justify-content-center">
        <p className="black-3 mb-6">
          <FormattedMessage id="updatedText" defaultMessage="Updated" />
          <FormattedDate value={promotion.publishedAt} year="numeric" month="short" day="2-digit" />
        </p>
      </div>
      <div className={styles.main}>
        <RichTextField className="pt-10 pb-5 black-2" content={promotion.termsAndConditions} />
      </div>
    </LayoutWrapper>
  );
};

export const query = graphql`
query SinglePromotionPageTermsAndConditions($id: ID!) {
  strapi {
    promotion(id: $id) {
      data {
        id
        attributes {
          title
          slug
          expiresAt: expires_at
          publishedAt
          termsAndConditions: terms_and_conditions
          localizations {
            data {
              attributes {
                locale
              }
            }
          }
        }
      }
    }
  }
}
`;

export default intlWrapperHOC(v4tov3HOC(TermsAndConditionsPage));
